import React from "react";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";

class BannerConsulting extends React.Component {
  constructor() {
    super();
    this.vantaRef = React.createRef();
    this.zoomLevel = 1.0;
      if (typeof window !== `undefined`) {
      const mediaQuery = window.matchMedia('(max-width: 1500px)')
      if (mediaQuery.matches) {
        this.zoomLevel = 0.5;
      } else {
        this.zoomLevel = 1.0;
      }
    }
  }
  componentDidMount() {
    this.vantaEffect = FOG({
      el: this.vantaRef.current,
      THREE: THREE,
      highlightColor: 0xff00f0,
      midtoneColor: 0xb3ff,
      zoom: this.zoomLevel
    });
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }
  render() {
    return (
      <section id="banner" className="style2 land-banner contact-banner" ref={this.vantaRef}>
      <div className="align-inner">
        <div className="inner contact-inner">
          <header className="major">
            <h1>Getting in touch</h1>
          </header>
          <div className="content">
            <p>
              Ideas don’t come out fully formed,<br/> they only become clearer as you work on them. 
            </p>
          </div>
        </div>
        <div className="contact-form">
          <form name="hero-form" method="POST" action="/confirmation" className="floating-form" data-netlify="true" netlify-honeypot="bot-field">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input name="name" type="text" id="name" required />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input name="email" type="text" id="email" required />
            </div>
            <div className="field">
              <label htmlFor="message">How can I help you?</label>
              <textarea name="message" id="message" rows="4"></textarea>
              <input name="bot-field" type="hidden"  />
              <input type="hidden" name="form-name" value="hero-form" />
            </div>
            <ul className="actions">
              <li>
                <input name="submit" type="submit" value="Get started today" />
              </li>
            </ul>
          </form>
        </div>
        </div>
      </section>
    );
  }
}
export default BannerConsulting;
