import React from "react";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerConsulting from "../components/BannerConsulting";

const LandingConsulting = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
          "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/contact/#webpage",
        url: "https://brandinavian.com/contact/",
        name: "Contact | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:
          "I'd love to talk to you, about how we can advance your situation. Get started with Brandinavian today!",
        breadcrumb: {
          "@id": "https://brandinavian.com/cro/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/contact"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://brandinavian.com/contact/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/contact",
              url: "https://brandinavian.com/contact",
              name: "Modern web development",
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout altLogo={"black"}>
    <SEO
       title="Contact | Brandinavian"
       lang="en-US"
       schemaMarkup={schema}
       description="I'd love to talk to you, about how we can advance your situation. Get started with Brandinavian today!"
     />
    <BannerConsulting/>
    <div id="main" className="alt darkmode">
      <section className="pre-article dark">
        <li className="tag">Practical information</li>
      </section>
      <section id="one" className="article-section main-service">
        <div className="inner article service-article">
          <header className="major">
            <h2>It's very simple</h2>
          </header>
          <p>
          No matter what kind of challenges you are facing, I'd love to talk to you. Let's figure out, how we can advance your situation. Simply reach out to me, and we will schedule a non-committal, exploratory call within a couple of days. Talking to me, and getting an estimation on your project, won't cost you a thing.
          </p>
          <div className="dark-mode-image blue">
            <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
            <li className="tag branded">BRANDINAVIAN</li>
          </div>
          <header className="major">
            <h2>How do you charge?</h2>
          </header>
          <p>
          In these trying times, I understand the need for
                   economic flexibility - especially when it comes to
                   establishing your start-up or taking your non-corporation
                   business to the next level. Because of this, I offer <b>flexible payment plans</b> to support your advancement. Depending on the
                   type of project, I will give you an estimate of the total amount of
                   hours that I will need to finish it - and you will have the
                   option to scale your payments down to a favorable percentage
                   of the total cost, <b>paid monthly</b>. We can also get the billing
                   out the way instantly - it is up to you. If you
                   are an agency looking for a vendor, I can't offer you the
                   same benefits directly, but I will be happy to discuss similar
                   alternatives.
</p>
          <div className="dark-mode-image green">
            <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
            <li className="tag branded">BRANDINAVIAN</li>
          </div>
                 <header className="major">
                  <h2>What about upkeep?</h2>
                </header>
                 <p>
                 In the event of me performing software-related services for
                   you, I will be more than happy to assist you in the ongoing
                   maintenance that is required in keeping your website or application up
                   to date. I will also make sure that your content management
                   system is being cared for in the same way, if you are using
                   one. I offer paid-monthly plans, that provide you with this 
                   maintenance - and with daily backups! These plans also allow you to leverage
                   me for <b>guaranteed personal assistance within 24 hours</b>, if
                   needed.

                 </p>
          <ul className="actions align-center">
            <li>
              <a href="#banner" className="button">
                Sounds good, let's talk
              </a>
            </li>
          </ul>
        </div>
        <div className="inner article about-author">
          <div className="author-image-box">
            <Img
              className="large-author-image"
              fluid={props.data.imageFour.childImageSharp.fluid}
            />
          </div>
          <div className="author-information">
            <header className="major special">
              <h2 className="large-author-name">Hi, I'm Mads</h2>
            </header>
            <p className="author-biography">
              I'm a Full Stack Engineer and Media Science graduate. Through
              Brandinavian, I help awesome people all over the world,
              designing and engineering digital solutions. I build
              best-in-class, scalable web applications, that convert and
              communicate well.
            </p>
            <p className="author-links" />
            <li className="tag">
              <a href="#banner">Get in touch</a>
            </li>
            <li className="tag">
              <a href="#banner">Request Resume</a>
            </li>
          </div>
        </div>
      </section>
    </div>
  </Layout>
  );
};

export default LandingConsulting;

export const contactQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "jetpackflag.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "bush.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
